/**
 * On document ready.
 */
$(document).ready(function () {

    /**
     * @param  {object} '.js-subscribe-button'
     */
    var $formSubmitBtn = $('.js-subscribe-button');

    /**
     * @param  {object} '.js-newsletter-loading'
     */
    var $loading = $('.js-newsletter-loading');

    /**
     * @param  {object} '.js-newsletter-success'
     */
    var $success = $('.js-newsletter-success');

    /**
     * @param  {string}
     */
    var classLoadingActive = 'modal-body__loading--active';

    /**
     * @param  {string}
     */
    var classSuccessActive = 'modal-body__success--active';

    /**
     * @param  {string}
     */
    var inputErrorClass = 'input-error';

    /**
     * @param  {string}
     */
    var subscribeRoute = $('.js-subscribe-route').val();

    // Get data
    var $email = $('#newsletter_email');
    var $firstName = $('#newsletter_firstName');
    var $lastName = $('#newsletter_lastName');
    var $company = $('#newsletter_company');
    var $firstAddress = $('#newsletter_firstAddress');
    var $secondAddress = $('#newsletter_secondAddress');
    var $city = $('#newsletter_city');
    var $county = $('#newsletter_county');
    var $postcode = $('#newsletter_postcode');
    var $country = $('#newsletter_country');
    var $telephone = $('#newsletter_telephone');
    var $fax = $('#newsletter_fax');
    var $terms = $('#newsletter_termsAndConditions');

    /**
     * Get form data.
     */
    function getData() {
      return {
        email: $email.val(),
        firstName: $firstName.val(),
        lastName: $lastName.val(),
        company: $company.val(),
        firstAddress: $firstAddress.val(),
        secondAddress: $secondAddress.val(),
        city: $city.val(),
        county: $county.val(),
        postcode: $postcode.val(),
        country: $country.val(),
        telephone: $telephone.val(),
        fax: $fax.val(),
        termsAndConditions: $terms.is(':checked'),
      };
    }

    /**
     * Validate email.
     * 
     * @param  {string} email
     */
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    /**
     * @param  {object} $el
     */
    function setInputError($el){
      $el.parent().addClass(inputErrorClass);
    }

    /**
     * Sets loading.
     *
     * @param {boolean} status
     */
    function setLoading(status) {
      if (status) {
        $loading.addClass(classLoadingActive);
      } else {
        $loading.removeClass(classLoadingActive);
      }
    }

    /**
     * Sets success.
     *
     * @param {boolean} status
     */
    function setSuccess(status) {
      if (status) {
        $success.addClass(classSuccessActive);
      } else {
        $success.removeClass(classSuccessActive);
      }
    }

    /**
     * Submit form function.
     */
    function submitForm() {
      // Show loading
      setLoading(true);

      // Gets data
      var formData = getData();

      // If not accepted
      if(!formData.termsAndConditions){
          // Sets input error
          setInputError($terms);
          // Hide loading
          setLoading(false);
          return;
      }

      // If not valid email
      if(!validateEmail(formData.email)){
        // Sets input error
        setInputError($email);
        // Hide loading
        setLoading(false);
        return;
      }

      // Ajax request
      $.ajax({
        type: 'POST',
        url: subscribeRoute,
        data: formData,
        dataType: 'json',
        accept: 'application/json',
      }).done(function(data) {
        console.log('success', data);
        // Hide loading
        setLoading(false);
        // Show success
        setSuccess(true);
      }).fail(function (data) {
        console.log('fail', data);
        // Hide loading
        setLoading(false);
      });
    }
  
    /**
     * Inits events.
     */
    function initEvents() {
      $formSubmitBtn.on('click', submitForm);
    }
  
    /**
     * Init component.
     */
    function init() {
      initEvents();
    }
  
    // Init
    init();
  });