/**
 * On document ready.
 */
$(document).ready(function () {
    /**
     * @param  {object} '.js-rental_signup_subscribe-button'
     */
    var $formSubmitBtn = $('.js-rental_signup_subscribe-button');
    /**
     * @param  {object} '.js-rental_signup-loading'
     */
    var $loading = $('.js-rental_signup-loading');
    /**
     * @param  {object} '.js-rental_signup-success'
     */
    var $success = $('.js-rental_signup-success');
    /**
     * @param  {string}
     */
    var classLoadingActive = 'modal-body__loading--active';
    /**
     * @param  {string}
     */
    var classSuccessActive = 'modal-body__success--active';
    /**
     * @param  {string}
     */
    var inputErrorClass = 'input-error';
    /**
     * @param  {string}
     */
    var subscribeRoute = $('.js-rental_signup_subscribe-route').val();
    // Get data
    var $email = $('#rental_signup_email');
    var $firstName = $('#rental_signup_firstName');
    var $lastName = $('#rental_signup_lastName');
    var $company = $('#rental_signup_company');
    var $firstAddress = $('#rental_signup_firstAddress');
    var $secondAddress = $('#rental_signup_secondAddress');
    var $city = $('#rental_signup_city');
    var $county = $('#rental_signup_county');
    var $postcode = $('#rental_signup_postcode');
    var $country = $('#rental_signup_country');
    var $telephone = $('#rental_signup_telephone');
    var $fax = $('#rental_signup_fax');
    var $mobile = $('#rental_signup_mobile');
    var $terms = $('#rental_signup_termsAndConditions');
    var $products = $('#rental_signup_products');
    /**
     * Get form data.
     */
    function getData() {
        return {
            email: $email.val(),
            firstName: $firstName.val(),
            lastName: $lastName.val(),
            company: $company.val(),
            firstAddress: $firstAddress.val(),
            secondAddress: $secondAddress.val(),
            city: $city.val(),
            county: $county.val(),
            postcode: $postcode.val(),
            country: $country.val(),
            telephone: $telephone.val(),
            fax: $fax.val(),
            mobile: $mobile.val(),
            termsAndConditions: $terms.is(':checked'),
            products: $products.val(),
        };
    }
    /**
     * Validate email.
     *
     * @param  {string} email
     */
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    /**
     * @param  {object} $el
     */
    function setInputError($el){
        $el.parent().addClass(inputErrorClass);
    }
    /**
     * Sets loading.
     *
     * @param {boolean} status
     */
    function setLoading(status) {
        if (status) {
            $loading.addClass(classLoadingActive);
        } else {
            $loading.removeClass(classLoadingActive);
        }
    }
    /**
     * Sets success.
     *
     * @param {boolean} status
     */
    function setSuccess(status) {
        if (status) {
            $success.addClass(classSuccessActive);
        } else {
            $success.removeClass(classSuccessActive);
        }
    }
    /**
     * Submit form function.
     */
    function submitForm() {
        // Show loading
        setLoading(true);
        // Gets data
        var formData = getData();

        if(!formData.email){
            // Sets input error
            setInputError($email);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.firstName){
            // Sets input error
            setInputError($firstName);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.lastName){
            // Sets input error
            setInputError($lastName);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.firstAddress){
            // Sets input error
            setInputError($firstAddress);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.city){
            // Sets input error
            setInputError($city);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.county){
            // Sets input error
            setInputError($county);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.postcode){
            // Sets input error
            setInputError($postcode);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.country){
            // Sets input error
            setInputError($country);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.products){
            // Sets input error
            setInputError($products);
            // Hide loading
            setLoading(false);
            return;
        }

        // If not accepted
        if(!formData.termsAndConditions){
            // Sets input error
            setInputError($terms);
            // Hide loading
            setLoading(false);
            return;
        }

        // If not valid email
        if(!validateEmail(formData.email)){
            // Sets input error
            setInputError($email);
            // Hide loading
            setLoading(false);
            return;
        }
        // Ajax request
        $.ajax({
            type: 'POST',
            url: subscribeRoute,
            data: formData,
            dataType: 'json',
            accept: 'application/json',
        }).done(function(data) {
            console.log('success', data);
            // Hide loading
            setLoading(false);
            // Show success
            setSuccess(true);
            // Hide submit button
            $('.js-rental_signup_subscribe-button').hide();
        }).fail(function (data) {
            console.log('fail', data);
            // Hide loading
            setLoading(false);
        });
    }
    /**
     * Inits events.
     */
    function initEvents() {
        $formSubmitBtn.on('click', submitForm);
    }
    /**
     * Init component.
     */
    function init() {
        initEvents();
    }
    // Init
    init();
});