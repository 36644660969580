/**
 * Created by torpedo on 19.07.18..
 */

$(document).ready(function () {

    $('.selector').on('change', function () {
        handleProductOptionTabs($(this))
    })

    function handleProductOptionTabs() {
        $('.variant-tabs').addClass('hidden');
        var options = '';
        $('.selector').each(function() {
            options += $(this).find('option:selected').val() + ' ';
        });
        $('[data-tab-parent-name]').each(function () {
            $(this).removeClass('hidden');
        });
        $('[data-variant-options]').each(function () {
            if (options == $(this).attr('data-variant-options')) {
                var tabName = $(this).attr('data-tab-name');
                $('[data-tab-parent-name]').each(function () {
                    if (tabName == $(this).attr('data-tab-parent-name')) {
                        $(this).addClass('hidden');
                    }
                })
                $(this).removeClass('hidden');
            }
        });
    }
});