/**
 * On document ready.
 */
$(document).ready(function () {
    /**
     * @param  {object} '.js-request_demo_subscribe-button'
     */
    var $formSubmitBtn = $('.js-request_demo_subscribe-button');
    /**
     * @param  {object} '.js-request_demo-loading'
     */
    var $loading = $('.js-request_demo-loading');
    /**
     * @param  {object} '.js-request_demo-success'
     */
    var $success = $('.js-request_demo-success');
    /**
     * @param  {string}
     */
    var classLoadingActive = 'modal-body__loading--active';
    /**
     * @param  {string}
     */
    var classSuccessActive = 'modal-body__success--active';
    /**
     * @param  {string}
     */
    var inputErrorClass = 'input-error';
    /**
     * @param  {string}
     */
    var subscribeRoute = $('.js-request_demo_subscribe-route').val();
    // Get data

    var $products = $('#request_demo_products');
    var $email = $('#request_demo_email');
    var $firstName = $('#request_demo_firstName');
    var $lastName = $('#request_demo_lastName');
    var $company = $('#request_demo_company');
    var $firstAddress = $('#request_demo_firstAddress');
    var $secondAddress = $('#request_demo_secondAddress');
    var $city = $('#request_demo_city');
    var $county = $('#request_demo_county');
    var $postcode = $('#request_demo_postcode');
    var $country = $('#request_demo_country');
    var $telephone = $('#request_demo_telephone');
    var $terms = $('#request_demo_termsAndConditions');

    /**
     * Get form data.
     */
    function getData() {
        return {
            products: $products.val(),
            email: $email.val(),
            firstName: $firstName.val(),
            lastName: $lastName.val(),
            company: $company.val(),
            firstAddress: $firstAddress.val(),
            secondAddress: $secondAddress.val(),
            county: $county.val(),
            city: $city.val(),
            postcode: $postcode.val(),
            country: $country.val(),
            telephone: $telephone.val(),
            termsAndConditions: $terms.is(':checked'),
        };
    }
    /**
     * Validate email.
     *
     * @param  {string} email
     */
    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    /**
     * @param  {object} $el
     */
    function setInputError($el){
        $el.parent().addClass(inputErrorClass);
    }
    /**
     * Sets loading.
     *
     * @param {boolean} status
     */
    function setLoading(status) {
        if (status) {
            $loading.addClass(classLoadingActive);
        } else {
            $loading.removeClass(classLoadingActive);
        }
    }
    /**
     * Sets success.
     *
     * @param {boolean} status
     */
    function setSuccess(status) {
        if (status) {
            $success.addClass(classSuccessActive);
        } else {
            $success.removeClass(classSuccessActive);
        }
    }
    /**
     * Submit form function.
     */
    function submitForm() {
        // Show loading
        setLoading(true);
        // Gets data
        var formData = getData();


        if(!formData.email){
            // Sets input error
            setInputError($email);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.firstName){
            // Sets input error
            setInputError($firstName);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.lastName){
            // Sets input error
            setInputError($lastName);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.firstAddress){
            // Sets input error
            setInputError($firstAddress);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.city){
            // Sets input error
            setInputError($city);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.county){
            // Sets input error
            setInputError($county);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.postcode){
            // Sets input error
            setInputError($postcode);
            // Hide loading
            setLoading(false);
            return;
        }

        if(!formData.country){
            // Sets input error
            setInputError($country);
            // Hide loading
            setLoading(false);
            return;
        }

        // If not accepted
        if(!formData.termsAndConditions){
            // Sets input error
            setInputError($terms);
            // Hide loading
            setLoading(false);
            return;
        }

        // If not valid email
        if(!validateEmail(formData.email)){
            // Sets input error
            setInputError($email);
            // Hide loading
            setLoading(false);
            return;
        }
        // Ajax request
        $.ajax({
            type: 'POST',
            url: subscribeRoute,
            data: formData,
            dataType: 'json',
            accept: 'application/json',
        }).done(function(data) {
            console.log('success', data);
            // Hide loading
            setLoading(false);
            // Show success
            setSuccess(true);
            // Hide submit button
            $('.js-request_demo_subscribe-button').hide();
        }).fail(function (data) {
            console.log('fail', data);
            // Hide loading
            setLoading(false);
        });
    }
    /**
     * Inits events.
     */
    function initEvents() {
        $formSubmitBtn.on('click', submitForm);
    }
    /**
     * Init component.
     */
    function init() {
        initEvents();
    }
    // Init
    init();
});