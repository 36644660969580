$(document).ready(function () {

    $('#carousel123').slick({
        dots: false,
        infinite: true,
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: $('.left-arr'),
        nextArrow: $('.right-arr'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });


    $('body').on('click', '.c-downloads__mobile-product', function () {
        if($(this).next('ul').hasClass('hidden')) {
            $(this).next('ul').removeClass('hidden');
            $('span.expand', this).text('-');
        } else {
            $(this).next('ul').addClass('hidden');
            $('span.expand', this).text('+');
        }

    });
});