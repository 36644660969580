    var map;
    var markers = [];
    var simpleDealers = [];
    var bounds;

    function initMap(zoom) {
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: zoom ? zoom : 2,
            center: {lat: 34.553127, lng: 18.048012}
        });
        if(simpleDealers.length > 0) {
            addMarkers(map)
        }
    }

    function addMarkers(map) {

        bounds = new google.maps.LatLngBounds();
        markers = [];

        simpleDealers.forEach(function(dealer, i) {
            if (dealer.lat.length && dealer.lng.length) {

                var marker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(dealer.lat, dealer.lng),
                    title: dealer.dealerName,
                    animation: google.maps.Animation.DROP,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                });

                var infowindow = new google.maps.InfoWindow({
                    content: dealer.infoBox
                });

                marker.addListener('click', function() {
                    infowindow.open(map, marker);
                });

                markers.push(marker);

                bounds.extend(marker.position);
                map.setCenter(marker.position);
            } else {
                console.error('Geocode was not successful for the following reason: ' + status);
            }
        });

        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        var listener = google.maps.event.addListener(map, "idle", function() {
            if (map.getZoom() > 16) map.setZoom(16);
            google.maps.event.removeListener(listener);
        });

    }


    function loadDealers(countryCode, isRental) {
        var dataUrl = $(document.getElementById('dealers')).attr('data-url');
        $.ajax({
            type: "GET",
            url: dataUrl,
            data: { "countryCode": countryCode, "isRental": isRental },
            dataType: "json",
            accept: "application/json",
            success: function(data) {
                if(data.view.length) {
                    $('.map-href').removeClass('hidden');
                    document.getElementById('dealers-holder').innerHTML = 'See results on the right...';
                } else {
                    $('.map-href').addClass('hidden');
                    document.getElementById('dealers-holder').innerHTML = '<span class="attention">No results...<span>';
                }
                simpleDealers = data.simpleDealers;
                simpleDealers.forEach(function(dealer, i) {
                    //$('#seller-holder').html(dealer.infoBox);
                    $('#dealers-holder').html(dealer.infoBox);
                    //$('#selected-country').text($('.dealer-country.active a').text());
                });

                /*
                var zoom = 2;
                if(simpleDealers.length > 0)
                    zoom = 5;
                initMap(zoom);
                */
            }
        });
    }

$(document).ready(function () {

    $('body').on('click', '.dealer-country', function () {
        var countryCode = $(this).attr('data-country-code');
        var isRental = $(this).attr('data-is-rental');
        $('#dealers-holder').html('<span class="attention">Loading...<span>');
        loadDealers(countryCode, isRental);

        $('.dealer-country-list').find('li.active').removeClass('active');
        $(this).addClass('active');
        $('#dealers-wrapper').insertAfter($(this));
    });

    $('body').on('click', '.c-dealers__zone', function () {
        $('.panel-collapse').removeClass('in');
    });

});
