/**
 * On document ready.
 */
$(document).ready(function () {
  /**
   * Gets loading element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $loading = $('.js-loading');

  /**
   * Gets footer element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $footer = $('.js-footer');

  /**
   * Gets news list element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $newsList = $('.js-news-list');

  /**
   * Get no news message element.
   *
   * @type {*|jQuery|HTMLElement}
   */
  var $noNewsMessage = $('.js-no-news-message');

  /**
   * News path.
   *
   * @type {*|jQuery}
   */
  var getNewsPath = $('.js-get-news-path').val();

  /**
   * Defines active loading class
   *
   * @type {string}
   */
  var classLoadingActive = 'c-news__loading--active';

  /**
   * Defines shown class for no news message
   *
   * @type {string}
   */
  var classNoNewsMessageShown = 'c-news__no-news-message--shown';

  /**
   * Defines current pagination page.
   *
   * @type {number}
   */
  var currentPaginationPage = 1;

  /**
   * Is scrolled flag.
   *
   * @type {boolean}
   */
  var isScrolled = false;

  /**
   * If has news.
   *
   * @type {boolean}
   */
  var hasNews = true;

  /**
   * Sets loading.
   *
   * @param {boolean} status
   */
  function setLoading(status) {
    if (status) {
      $loading.addClass(classLoadingActive);
    } else {
      $loading.removeClass(classLoadingActive);
    }
  }

  /**
   * Detect is user scrolled to bottom.
   *
   * @return {boolean}
   */
  function isScrolledToBottom() {
    return $(window).scrollTop() + $(window).height() >= $(document).height() - $footer.outerHeight();
  }

  /**
   * Append news data to news list.
   * @param data
   */
  function appendNewsDataToNewsList(data) {
    $newsList.append(data);
  }

  /**
   * Show no news message
   */
  function showNoNewsMessage() {
    $noNewsMessage.addClass(classNoNewsMessageShown);
  }

  /**
   * Gets news data.
   */
  function getNewsData() {
    if (hasNews) {
      setLoading(true);
      if (!isScrolled) {
        isScrolled = true;
        currentPaginationPage++;
        $.ajax(getNewsPath + '?page=' + currentPaginationPage)
          .done(function (data) {
            appendNewsDataToNewsList($(data));
            isScrolled = false;
            setLoading(false);
          })
          .fail(function (data) {
            isScrolled = false;
            setLoading(false);
            if (data.status === 404) {
              hasNews = false;
              showNoNewsMessage();
            }
          });
      }
    }
  }

  /**
   * Function that fires on scroll event.
   */
  function onScroll() {
    // If is scrolled to bottom
    if (isScrolledToBottom()) {
      // Get news data
      getNewsData();
    }
  }

  /**
   * Inits events.
   */
  function initEvents() {
    $(window).on('scroll', onScroll);
  }

  /**
   * Init component.
   */
  function init() {
    initEvents();
  }

  // Init
  init();
});